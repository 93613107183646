export default {
  badge: 'Insígnia',
  close: 'Tancar',
  dataIterator: {
    noResultsText: 'Sense dades per mostrar',
    loadingText: 'Carregant...',
  },
  dataTable: {
    itemsPerPageText: 'Files per pàgina:',
    ariaLabel: {
      sortDescending: 'Ordre descendent. Premi per treure la ordenació.',
      sortAscending: 'Ordre ascendent. Premi per ordenar descendent.',
      sortNone: 'Sense ordenar. Premi per ordenar ascendent.',
      activateNone: 'Activate to remove sorting.',
      activateDescending: 'Activate to sort descending.',
      activateAscending: 'Activate to sort ascending.',
    },
    sortBy: 'Ordenat per',
  },
  dataFooter: {
    itemsPerPageText: 'Elements per pàgina:',
    itemsPerPageAll: 'Tot',
    nextPage: 'Pàgina següent',
    prevPage: 'Pàgina anterior',
    firstPage: 'Primera pàgina',
    lastPage: 'Última pàgina',
    pageText: '{0}-{1} de {2}',
  },
  datePicker: {
    itemsSelected: '{0} seleccionats',
    nextMonthAriaLabel: 'El mes que ve',
    nextYearAriaLabel: 'L\'any que ve',
    prevMonthAriaLabel: 'Mes anterior',
    prevYearAriaLabel: 'Any anterior',
  },
  noDataText: 'Sense dades',
  carousel: {
    prev: 'Visualització prèvia',
    next: 'Visualització següent',
    ariaLabel: {
      delimiter: 'Diapositiva {0} of {1}',
    },
  },
  calendar: {
    moreEvents: '{0} més',
  },
  fileInput: {
    counter: '{0} fitxers',
    counterSize: '{0} fitxers ({1} en total)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Navegació de la pàgina',
      next: 'Pàgina següent',
      previous: 'Pàgina anterior',
      page: 'Ves a la pàgina {0}',
      currentPage: 'Pàgina actual, pàgina {0}',
    },
  },
}
