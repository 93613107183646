export default {
  badge: 'Ženklelis',
  close: 'Uždaryti',
  dataIterator: {
    noResultsText: 'Nerasta atitinkančių įrašų',
    loadingText: 'Kraunama...',
  },
  dataTable: {
    itemsPerPageText: 'Eilutės per puslapį:',
    ariaLabel: {
      sortDescending: 'Išrikiuota mažėjimo tvarka.',
      sortAscending: 'Išrikiuota didėjimo tvarka.',
      sortNone: 'Nerikiuota.',
      activateNone: 'Suaktyvinkite, jei norite rikiavimą pašalinti.',
      activateDescending: 'Suaktyvinkite, jei norite rikiuoti mažėjimo tvarka.',
      activateAscending: 'Suaktyvinkite, jei norite rikiuoti didėjimo tvarka.',
    },
    sortBy: 'Sort by',
  },
  dataFooter: {
    itemsPerPageText: 'Įrašai per puslapį:',
    itemsPerPageAll: 'Visi',
    nextPage: 'Kitas puslapis',
    prevPage: 'Ankstesnis puslapis',
    firstPage: 'Pirmas puslapis',
    lastPage: 'Paskutinis puslapis',
    pageText: '{0}-{1} iš {2}',
  },
  datePicker: {
    itemsSelected: '{0} pasirinkta',
    nextMonthAriaLabel: 'Kitą mėnesį',
    nextYearAriaLabel: 'Kitais metais',
    prevMonthAriaLabel: 'Praeita mėnesį',
    prevYearAriaLabel: 'Praeiti metai',
  },
  noDataText: 'Nėra duomenų',
  carousel: {
    prev: 'Ankstesnioji skaidrė',
    next: 'Kita skaidrė',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: 'Daugiau {0}',
  },
  fileInput: {
    counter: '{0} failų',
    counterSize: '{0} failų ({1} iš viso)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Puslapio naršymas',
      next: 'Kitas puslapis',
      previous: 'Ankstesnis puslapis',
      page: 'Eiti į puslapį {0}',
      currentPage: 'Dabartinis puslapis, puslapis {0}',
    },
  },
}
